import styled from 'styled-components';

export const Button = styled.button`
    border-radius: .25rem;
    cursor: pointer;
    justify-content: center;
    align-content: center;
    text-align: center;
    margin: 0;
    outline: none;
    border: 0;
    line-height: 0;
    padding-inline: 0;
    padding-block: 0;
    box-sizing: unset;
    
    padding: 1.25rem;
    height: 0;
    display: flex;
    flex-direction: column;
    color: var(--color-text-invert);

    &[disabled] {
        cursor: not-allowed;
        color: var(--color-text);
    }
    &:not([disabled]):hover {
        color: var(--color-text-invert-hover);
    }

    &.btn-ghost {
        background-color: unset;
        border: .1rem solid var(--color-text);
        box-shadow: .125rem .125rem .25rem rgba(var(--color-text-numbers),.25);
    }
    &.success {
        color: var(--color-text-success);

        &:hover {
            color: var(--color-text-success-hover);
        }

        &.btn-ghost {
            background-color: unset;
            border: .1rem solid var(--color-text-success);
            box-shadow: .125rem .125rem .25rem rgba(var(--color-text-success-numbers), .2);
        }
    }
    &.error {
        color: var(--color-text-error);

        &:hover {
            color: var(--color-text-error-hover);
        }
        &.btn-ghost {
            background-color: unset;
            border: .1rem solid var(--color-text-error);
            box-shadow: unset;
        }
    }
`;
