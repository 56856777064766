import React from "react";
import Home from "./page/home";
import Account from "./page/account";
import Guide from "./page/guide";
import News from "./page/news";
import AI from "./page/artificial_intelligence";
import {ConverterFormulas} from "./page/converters";
import Contact from "./page/contact";
import Feedback from "./page/feedback";
import Cookies from "./page/legal/cookies";
import PrivacyPolicy from "./page/legal/privacy_policy";
import TermsAndConditions from "./page/legal/terms_and_conditions";
import Games from "./page/games";
import AvailableGames from "./page/games/index";
import Test from "./page/test";

export type pagesType = {
    preTitle?: string;
    title: string;
    subTitle?: string | React.ReactNode;
    link?: string;
    index?: number;
    nextIndex?: number;
    pageInfo?: {
        pageNumber: number;
        pages: number;
        difference: number;
        index?: number;
        nextIndex?: number;
    };
    pages: {
        isAd?: boolean;
        preTitle?: string;
        title?: string;
        subTitle?: string | React.ReactNode;
        text?: string | React.ReactNode;
        youtubeId?: string;
        youtubeIdFull?: string;
        imageSrc?: string;
    }[]
}[];

export type pageType = {
    name: string;
    link: string[];
    page: React.ReactNode;
    props?: {
        enabled?: boolean;
        redirect?: string;
        login?: boolean;
        menu?: boolean;
    };
};

const pages: pageType[] = [
    Home,
    // AI,
    Guide,
    ...Account,
    ...ConverterFormulas,
    News,

    // Contact/Feedback
    Contact,
    Feedback,

    // Legally
    Cookies,
    PrivacyPolicy,
    TermsAndConditions,

    // Games
    Games,
    ...AvailableGames.AgersCustom.filter((item) => item.props?.enabled === true),

    // Test
    Test,
];

export default pages;