import React            from 'react';
import styled           from 'styled-components';
import MuiImagePerson   from '@mui/icons-material/Person';
import MuiImagePassword from '@mui/icons-material/Password';
import MuiImageEmail    from '@mui/icons-material/AlternateEmail';
import MuiImageNote     from "@mui/icons-material/Notes";
import MuiImageList     from "@mui/icons-material/List";

const StyledContainer = styled.div`
    position: relative;
    display: flex;
    flex-grow: 1;
`;
const StyledContainerColumn = styled(StyledContainer)`
    flex-direction: column;
`;
const StyledContainerRow = styled(StyledContainer)`
    flex-direction: row;
`;
const StyledContainerColMax = styled(StyledContainerColumn)`
    max-height: 3.5rem;
`;

const StyledLabelError = styled.label`
    display: flex;
    border-radius: .25rem;
    margin: .25rem 0 0 0;
    padding: .25rem .75rem;
    white-space: pre-wrap;
    word-break: break-word;
    overflow: hidden;
    color: var(--color-text-error);
    background-color: var(--color-background-error);

    &:hover {
        color: var(--color-text-error-hover);
        background-color: var(--color-background-error-hover);
    }
`;
const StyledLabelNoError = styled.label`
    display: flex;
    margin: .25rem 0 0 0;
    height: 1.1875rem;
    padding: .25rem .75rem;
    overflow: hidden;
`;
const StyledLabel = styled.label`
    display: flex;
    padding: 1rem 1.25rem;
    border-radius: .25rem;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: var(--color-background-label);
`;
const StyleLabelColorRed = styled(StyledLabel)`
    color: var(--color-text-error);
`;
const StyleLabelColorGreen = styled(StyledLabel)`
    color: var(--color-text-success-darker);
`;
const StyledImagePerson = styled(MuiImagePerson)`
    max-width: 1.5rem;
    max-height: 1.5rem;
    color: var(--color-text-invert);
`;
const StyledImagePassword = styled(MuiImagePassword)`
    max-width: 1.5rem;
    max-height: 1.5rem;
    color: var(--color-text-invert);
`;
const StyledImageEmail = styled(MuiImageEmail)`
    max-width: 1.5rem;
    max-height: 1.5rem;
    color: var(--color-text-invert);
`;
const StyledImageNote = styled(MuiImageNote)`
    display: flex;
    flex-direction: column;
    align-self: baseline;
    max-width: 1.5rem;
    max-height: 1.5rem;
    color: var(--color-text-invert);
`;
const StyledImageList = styled(MuiImageList)`
    max-width: 1.5rem;
    max-height: 1.5rem;
    color: var(--color-text-invert);
`;

const StyledInput = styled.input`
    display: flex;
    flex-grow: 1;
    border-radius: .25rem;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background-image: none;
    border: 0;
    color: inherit;
    font: inherit;
    margin: 0;
    outline: 0;
    padding: 1rem 4rem 1rem 1.25rem;
    transition: background-color .3s;
    color: var(--color-text);
    background-color: var(--color-background-input);
    
    &.success {
        color: var(--color-text-success);
        background-color: var(--color-background-input-success);
    }
    &.error {
        color: var(--color-text-error);
        background-color: var(--color-background-input-danger);
    }
    &:hover {
        color: var(--color-text-invert-hover);
    }
    &:is(:hover, :focus) {
        background-color: var(--color-background-input-hover);
    }
    &.success:is(:hover, :focus) {
        background-color: var(--color-background-input-success-hover);
    }
    &.error:is(:hover, :focus) {
        background-color: var(--color-background-input-danger-hover);
    }
    &:disabled {
        cursor: not-allowed;
        color: var(--color-text-error);
        background-color: var(--color-background-input-danger);
    }
`;

const StyledButton = styled(StyledInput)`
    border-radius: .25rem;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
`;
const StyledButtonGreen = styled(StyledButton)`
    color: var(--color-text-success);
    background-color: var(--color-background-input-success);
    
    &:hover {
        color: var(--color-text-success-hover);
        background-color: var(--color-background-input-success-hover);
    }
`;

const StyledLengthCharacters = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    padding: .25rem .5rem;
    transition: all .2s ease-in-out;

    &.success {
        color: var(--color-text-success);
        background-color: var(--color-background-input-success);
    }
    &.error {
        color: var(--color-text-error);
        background-color: var(--color-background-input-danger);
    }
`;

interface Props {
    type: `text` | `password` | `email` | `submit`;
    placeholder: string;
    iconName?: string;
    errorEnabled?: boolean;
    error?: string;
    success?: boolean;
    showLength?: boolean;
    currentLength?: number;
    maxLength?: number;
    noIcon?: boolean;
    [key: string]: any;
}

interface State {
    errorEnabled: boolean;
    inputRef: React.RefObject<HTMLInputElement>;
    buttonRef: React.RefObject<HTMLInputElement>;
}

class Input extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            errorEnabled: this.props.errorEnabled !== undefined ? this.props.errorEnabled : true,
            inputRef: React.createRef<HTMLInputElement>(),
            buttonRef: React.createRef<HTMLInputElement>(),
        };
    }

    getIcon() {
        const icons: {[iconName: string]: React.ReactNode} = {
            'email'       : <StyledImageEmail/>,
            'username'    : <StyledImagePerson/>,
            'password'    : <StyledImagePassword/>,
            'description' : <StyledImageNote/>,
            'list'        : <StyledImageList/>,
        };

        if (this.props.iconName) {
            return icons.hasOwnProperty(this.props.iconName) && icons[this.props.iconName];
        }

        return this.props.placeholder && icons[this.props.placeholder.toLowerCase()];
    }

    getLabel() {
        const content = this.props.placeholder && this.getIcon();
        switch (true) {
            case this.props.error && this.props.error.length > 0:
                return <StyleLabelColorRed
                    title={this.props.placeholder}
                >
                    {content}
                </StyleLabelColorRed>;
            case this.props.success:
                return <StyleLabelColorGreen
                    title={this.props.placeholder}
                >
                    {content}
                </StyleLabelColorGreen>;
            default:
                return <StyledLabel
                    title={this.props.placeholder}
                >
                    {content}
                </StyledLabel>;
        }
    }

    getButton() {
        const isDisabled = (this.props.error !== undefined && this.props.error.length > 0)
            || this.props.success === false;

        let Element = this.props.success
            ? StyledButtonGreen
            : StyledButton;

        return <Element
            ref={this.state.buttonRef}
            value={this.props.placeholder}
            disabled={isDisabled}
            className={`${this.props.error && this.props.error.length > 0 ? `error` : ``} ${this.props.success ? `success` : ``}`}
            {...this.props}
        />;
    }

    getFocus() {
        this.state.inputRef.current?.focus();
    }

    click() {
        this.state.buttonRef.current?.click();
    }

    render() {
        return <StyledContainerColMax>
            <StyledContainerColumn>
                {
                    this.props.type === `submit`
                        ? this.getButton()
                        : <StyledContainerRow>
                            {this.props.noIcon !== true && this.getLabel()}
                            <StyledInput
                                ref={this.state.inputRef}
                                className={`${this.props.error && this.props.error.length > 0 ? `error` : ``} ${this.props.success ? `success` : ``}`}
                                maxLength={this.props.maxLength}
                                {...this.props}
                            />
                            {
                                this.props.showLength
                                && this.props.currentLength !== undefined
                                && this.props.maxLength
                                && <StyledLengthCharacters
                                    className={`${this.props.error && this.props.error.length > 0 ? `error` : ``} ${this.props.success ? `success` : ``}`}
                                >
                                    {this.props.currentLength}/{this.props.maxLength}
                                </StyledLengthCharacters>
                            }
                        </StyledContainerRow>
                }
                {
                    this.state.errorEnabled
                    && (
                        this.props.error
                            ? <StyledLabelError>{this.props.error}</StyledLabelError>
                            : <StyledLabelNoError/>
                    )
                }
            </StyledContainerColumn>
        </StyledContainerColMax>;
    }
}

export default Input;