import React from 'react';
import styled     from 'styled-components';
import Body       from "../../component/body";
import { Button } from "../../component/form/button";
import DateTime   from "../../utils/datetime";
import { ws } from "../../utils";
import ReactDOM from "react-dom/client";
import Ads from "../../component/ads";

const StyledLoading = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
`;

const StyledButton = styled(Button)`
    white-space: nowrap;
`;

const StyledFlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;

const StyledContainer = styled(StyledFlexContainer)`
    flex-direction: column;
    flex: 1;

    min-width: 30vh;
`;

const StyledContent = styled.div`
    flex: 1;
    padding: 1rem 0;
`;

const StyledFooter = styled(StyledFlexContainer)`
    justify-content: space-between;
    width: 100%;
    
    & > *:not(:first-child, :last-child) {
        margin: 0 .5rem;
    }
`;


const StyledArticleContainer = styled.div`
`;
const StyledArticleTitle = styled.h1`
    margin: 0;
    padding: .25rem 0;
`;
const StyledArticleAuthor = styled.h3`
    margin: 0;
    padding: .25rem 0;
`;
const StyledPublishedDate = styled.h4`
    margin: 0;
    padding: .25rem 0;
`;
const StyledArticleTags = styled.h5`
    margin: 0;
    padding: .25rem 0;
`;
const StyledArticleContent = styled.pre`
    background-color: rgba(255, 255, 255, .2);
    padding: 1rem;
    border-radius: .3rem;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 50vh;
    word-break: break-word;
    word-wrap: break-word;
    white-space: pre-wrap;
`;

const StyledArticleDisclaimer = styled.div`
    font-size: .75rem;
`;

type PageType = {
    header: string;
    subHeader: string;
    content: string|React.ReactNode;
    navigation: {
        current: Date;
        options: Date[];
        thresholds: {
            date: {
                minimum: Date;
                maximum: Date;
            };
        };
        buttonEnabled: {
            previous: boolean;
            next: boolean;
        };
    };
};
type NewsType = {
    topic: string;
    publishDate: string;
    article: {
        author: string;
        title: string;
        content: string;
        tags: string[];
    };
};

interface Props {
    baseUrl: string;
    topic: string;
    date: string;
}
interface State {
    dateFormat: {
        display: string;
        use: string;
    };
    page: PageType;
    news: NewsType;
    errors: (string|React.ReactNode)[];
    loading: boolean;
    retries: number;
}

export default class NewsOnDate extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            dateFormat: {
                display: 'd-m-Y',
                use: 'Y-m-d',
            },
            page : {
                header     : '',
                subHeader  : '',
                content    : '',
                navigation : {
                    current : DateTime.createFromDateString(this.props.date),
                    options : [],
                    thresholds: {
                        date: {
                            minimum: new Date(2024, 1, 15),
                            maximum: new Date()
                        },
                    },
                    buttonEnabled: {
                        previous: false,
                        next: false,
                    }
                },
            },
            news             : {
                topic       : '',
                publishDate : '',
                article     : {
                    title   : '',
                    author  : '',
                    content : '',
                    tags    : [],
                },
            },

            errors: [],
            loading: false,
            retries: 0,
        };
    }

    hasErrors = (): boolean => this.state.errors.length !== 0
        || this.state.news.topic.length === 0
        || this.state.news.topic.toLowerCase() === 'not found'
        || this.state.news.publishDate.length === 0
        || this.state.news.article.author.length === 0
        || this.state.news.article.title.length === 0
        || this.state.news.article.content.length === 0;

    getArticle = (date: Date|string) => this.setState({
        loading: true,
    }, () => this.fetchArticle(date));

    private fetchArticle = (date: Date|string) => {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        let page: PageType = {...this.state.page};
        let news: NewsType = {
            topic       : 'not found',
            publishDate : '',
            article     : {
                title   : '',
                author  : '',
                content : '',
                tags    : [],
            },
        };

        const isInvalidTopic = this.props.topic.length === 0;
        const isInvalidDate = date.toString() === 'Invalid Date';
        const firstPubliclyArtificialIntelligenceDate = this.state.page.navigation.thresholds.date.minimum;
        const todayDate = new Date();
        let errors = [];

        if (isInvalidTopic) {
            const exampleLink = `/news/artificial-intelligence/${DateTime.formattedDate(todayDate, 'Y-m-d')}`;
            errors.push(<>
                <li>
                    '{this.props.topic}' is an invalid topic.
                    <ul>
                        <li>Please use this format in the url: {'/news/<topic>/<date>'}.</li>
                        <li>Example: <a href={exampleLink}>{exampleLink}</a></li>
                    </ul>
                </li>
            </>);
        }
        if (isInvalidDate) {
            errors.push(<>
                <li>
                    '{this.props.date}' is an invalid date/format.
                    <ul>
                        <li>Please use this format in the url: Year-month-day.</li>
                        <li>Example: {DateTime.formattedDate(todayDate, 'Y-m-d')}</li>
                    </ul>
                </li>
            </>);
        }
        if (!isInvalidDate && date < firstPubliclyArtificialIntelligenceDate) {
            errors.push(<>
                <li>AI was not publicly available before {DateTime.formattedDate(firstPubliclyArtificialIntelligenceDate, this.state.dateFormat.display)}</li>
            </>);
        }
        if (!isInvalidDate && date > todayDate) {
            errors.push(<>
                <li>AI can't look into the future, therefore news is not available after {DateTime.formattedDate(todayDate, this.state.dateFormat.display)}</li>
            </>);
        }

        if (errors.length !== 0) {
            page.header = 'Article not found.';
        }

        page.subHeader          = 'Error';
        page.navigation.current = new Date('invalid');

        if (errors.length !== 0) {
            this.setState({
                loading: false,
                page,
                news,
                errors
            }, () => this.checkNavigation());

            return;
        }

        ws.sent(
            `news`,
            `obtain`,
            {
                topic : this.props.topic,
                date  : DateTime.formattedDate(date, this.state.dateFormat.use),
            },
        );
        setTimeout(
            () => this.state.loading && this.setState({
                loading: false,
                page: {
                    ...this.state.page,
                    header: 'Timeout'
                },
                errors: [
                    <li>
                        Timeout while fetching the news. Please try again later.<br/>
                        {
                            this.state.retries < 5
                                ? <button onClick={() => this.setState({
                                    loading : true,
                                    retries : this.state.retries + 1,
                                }, () => this.fetchArticle(date))}>Retry</button>
                                : <ul>
                                    <li>Maximum retries reached.</li>
                                </ul>
                        }
                    </li>
                ]
            }),
            10000
        );
    };

    private navigatePage(addDays: number, date?: Date) {
        const newDate = DateTime.addDay(addDays, date || this.state.page.navigation.current);
        window.history.pushState({}, '', `/${this.props.baseUrl}/${this.props.topic}/${DateTime.formattedDate(newDate, "Y-m-d")}`);
        this.getArticle(newDate);
    }

    private checkNavigation() {
        this.setState({
            page: {
                ...this.state.page,
                navigation: {
                    ...this.state.page.navigation,
                    buttonEnabled: {
                        previous : DateTime.formattedDate(this.state.page.navigation.current, this.state.dateFormat.use) <= DateTime.formattedDate(this.state.page.navigation.thresholds.date.minimum, this.state.dateFormat.use),
                        next     : DateTime.formattedDate(this.state.page.navigation.current, this.state.dateFormat.use) >= DateTime.formattedDate(this.state.page.navigation.thresholds.date.maximum, this.state.dateFormat.use),
                    }
                },
            },
        });
    }

    componentDidMount() {
        this.getArticle(DateTime.createFromDateString(this.props.date));

        ws.listener.message(
            [`news.obtain`],
            data => {
                switch (data.code) {
                    case 200:
                        const errors: string[] = data.results?.errors || [];
                        let page: PageType = {...this.state.page};
                        let news: NewsType = {
                            topic       : 'not found',
                            publishDate : '',
                            article     : {
                                title   : '',
                                author  : '',
                                content : '',
                                tags    : [],
                            },
                        };

                        if (errors.length !== 0) {
                            page.header = "Error";
                        } else {
                            news = {
                                topic       : data.results?.topic || '',
                                publishDate : data.results?.publishDate || '',
                                article     : {
                                    title   : data.results?.article?.title || '',
                                    author  : data.results?.article?.author || '',
                                    content : (data.results?.article?.content || '').replace(/{{ads}}/g, `<div class="agers-box"></div>`),
                                    tags    : data.results?.article?.tags || [],
                                },
                            };

                            page.navigation.current = DateTime.createFromDateString(news.publishDate);

                            const today = DateTime.createFromDateString(data.results?.publishDate || DateTime.formattedDate(new Date(), 'Y-m-d'));

                            if (today.toString() !== 'Invalid Date') {
                                page.navigation.options = [
                                    DateTime.addDay(-2, today),
                                    DateTime.addDay(-1, today),
                                    today,
                                    DateTime.addDay(+1, today),
                                    DateTime.addDay(+2, today),
                                ];
                            }
                        }

                        this.setState({
                            loading: false,
                            page,
                            news,
                            errors
                        }, () => {
                            window.history.pushState({}, '', `/${this.props.baseUrl}/${this.props.topic}/${DateTime.formattedDate(page.navigation.current, "Y-m-d")}`);
                            Array.from(document.getElementsByClassName(`agers-box`)).forEach((element) => ReactDOM.createRoot(element as HTMLElement).render(<Ads adType={`page`} />));
                            this.checkNavigation();
                        });
                        break;
                    default:
                        break;
                }
            },
        );
    }

    render() {
        return <Body
            background={true}
        >
            <StyledContainer>
                {
                    this.state.loading
                        ? <StyledLoading>
                            loading...
                    </StyledLoading>
                        : <>
                            <StyledContent>
                                {
                                    this.hasErrors()
                                        ? <StyledArticleContainer>
                                            <StyledArticleTitle>{this.state.page.header}</StyledArticleTitle>
                                            {this.state.errors.length === 1 ? this.state.errors[0] : <ul>{this.state.errors}</ul>}
                                        </StyledArticleContainer>
                                        : <StyledArticleContainer>
                                            <StyledArticleTitle>{this.state.news.article.title}</StyledArticleTitle>
                                            <StyledArticleAuthor>Author: {this.state.news.article.author}</StyledArticleAuthor>
                                            <StyledPublishedDate>Published: {this.state.news.publishDate}</StyledPublishedDate>
                                            <StyledArticleTags>{this.state.news.article.tags.join(', ')}</StyledArticleTags>
                                            <StyledArticleContent dangerouslySetInnerHTML={{__html : this.state.news.article.content}} />
                                            <StyledArticleDisclaimer>This article is generated by AI, therefore it may or may not be true. Agers.nl is responsible for the outcome of this news article.</StyledArticleDisclaimer>
                                        </StyledArticleContainer>
                                }
                            </StyledContent>
                            {
                                this.state.page.navigation.options.length !== 0
                                && <StyledFooter>
                                    <Button
                                        disabled={this.state.page.navigation.buttonEnabled.previous}
                                        onClick={() => this.navigatePage(-1)}
                                    >{'< Previous'}</Button>
                                    {
                                        this.state.page.navigation.options.map(
                                            navigationOption => <StyledButton
                                                disabled={
                                                    DateTime.formattedDate(navigationOption, this.state.dateFormat.use) === DateTime.formattedDate(this.state.page.navigation.current, this.state.dateFormat.use)
                                                    || DateTime.formattedDate(navigationOption, this.state.dateFormat.use) < DateTime.formattedDate(this.state.page.navigation.thresholds.date.minimum, this.state.dateFormat.use)
                                                    || DateTime.formattedDate(navigationOption, this.state.dateFormat.use) > DateTime.formattedDate(this.state.page.navigation.thresholds.date.maximum, this.state.dateFormat.use)
                                                }
                                                onClick={() => this.navigatePage(0, navigationOption)}
                                            >
                                                {DateTime.formattedDate(navigationOption, this.state.dateFormat.display)}
                                            </StyledButton>
                                        )
                                    }
                                    <Button
                                        disabled={this.state.page.navigation.buttonEnabled.next}
                                        onClick={() => this.navigatePage(+1)}
                                    >{'Next >'}</Button>
                                </StyledFooter>
                            }
                        </>
                }
            </StyledContainer>
        </Body>;
    }
}