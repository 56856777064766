import { pageType } from "../../pages";
import NewsOnDate   from "./news_on_date";
import React        from "react";
import DateTime     from "../../utils/datetime";


const baseUrl = "/news";
const getUrlInformation = () => {
    const urlInformation = window.location.pathname.split('/');

    return {
        baseUrl    : urlInformation[1] || baseUrl,
        pathName   : urlInformation[2] || '',
        parameters : urlInformation[3] || '',
    };
};

const page: pageType = {
    name: `News AI`,
    link: [
        `${baseUrl}/artificial-intelligence/${DateTime.formattedDate(new Date(), 'Y-m-d')}`,
        `${baseUrl}/*`,
    ],
    page: <NewsOnDate
        baseUrl={getUrlInformation().baseUrl}
        topic={getUrlInformation().pathName}
        date={getUrlInformation().parameters || DateTime.formattedDate(new Date(), 'Y-m-d')}
    />,
    props: {
        menu    : true,
        login   : false,
        redirect: `/home`
    },
};

export default page;