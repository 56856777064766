import storage from "../storage";
import {z} from "../index";

const DateTime = {
    create: (date?: Date): Date => date ? new Date(date) : new Date(),
    createFromString: (datetimeString: string): Date|undefined => {
        try {
            return new Date(Date.parse(datetimeString));
        } catch (e) {
        }

        return undefined;
    },
    createFromDateString: (dateString: string): Date => {
        // Remove any non-alphanumeric characters to handle both separated and unseparated formats
        const cleanStr = dateString.replace(/[^0-9]/g, '');

        // If the cleaned string isn't exactly 8 digits, return invalid date
        if (cleanStr.length !== 8) {
            return new Date('invalid');
        }

        // Split into chunks and parse as numbers
        const firstTwo = parseInt(cleanStr.substring(0, 2));
        const secondTwo = parseInt(cleanStr.substring(2, 4));
        const lastFour = parseInt(cleanStr.substring(4, 8));

        // Also get the first four for Y-m-d format
        const firstFour = parseInt(cleanStr.substring(0, 4));

        // Helper function to check if a number could be a year (4 digits between 1900-9999)
        const isYear = (num: number): boolean => num >= 1900 && num <= 9999;

        // Helper function to check if a number could be a month (1-12)
        const isMonth = (num: number): boolean => num >= 1 && num <= 12;

        // Helper function to check if a number could be a day (1-31)
        const isDay = (num: number): boolean => num >= 1 && num <= 31;

        // Helper function to create and validate a date
        const createDate = (year: number, month: number, day: number): Date | null => {
            const date = new Date(year, month - 1, day);
            if (
                !isNaN(date.getTime()) &&
                date.getFullYear() === year &&
                date.getMonth() === month - 1 &&
                date.getDate() === day
            ) {
                return date;
            }
            return null;
        };

        // Try different combinations based on possible positions
        const possibleDates: (Date | null)[] = [];

        // Case 1: First four digits are year (Y-m-d or Y-d-m)
        if (isYear(firstFour)) {
            const lastTwo = parseInt(cleanStr.substring(6, 8));
            const midTwo = parseInt(cleanStr.substring(4, 6));

            if (isMonth(midTwo) && isDay(lastTwo)) {
                possibleDates.push(createDate(firstFour, midTwo, lastTwo)); // Y-m-d
            }
            if (isDay(midTwo) && isMonth(lastTwo)) {
                possibleDates.push(createDate(firstFour, lastTwo, midTwo)); // Y-d-m
            }
        }

        // Case 2: Last four digits are year (d-m-Y or m-d-Y)
        if (isYear(lastFour)) {
            if (isMonth(secondTwo) && isDay(firstTwo)) {
                possibleDates.push(createDate(lastFour, secondTwo, firstTwo)); // d-m-Y
            }
            if (isDay(secondTwo) && isMonth(firstTwo)) {
                possibleDates.push(createDate(lastFour, firstTwo, secondTwo)); // m-d-Y
            }
        }

        // Find the first valid date
        const validDate = possibleDates.find(date => date !== null);

        return validDate || new Date('invalid');
    },
    addYear: (years: number, date?: Date): Date => {
        date = date instanceof Date ? date : new Date();
        date.setFullYear(date.getFullYear() + years);

        return date;
    },
    addMonth: (months: number, date?: Date): Date => {
        date = date instanceof Date ? date : new Date();
        date.setMonth(date.getMonth() + months);

        return date;
    },
    addDay: (days: number, date?: Date): Date => {
        date = new Date(date instanceof Date ? date : new Date());
        date.setDate(date.getDate() + days);

        return date;
    },
    addHour: (hours: number, date?: Date): Date => {
        date = date instanceof Date ? date : new Date();
        date.setHours(date.getHours() + hours);

        return date;
    },
    addMinute: (minutes: number, date?: Date): Date => {
        date = date instanceof Date ? date : new Date();
        date.setMinutes(date.getMinutes() + minutes);

        return date;
    },
    addSecond: (seconds: number, date?: Date): Date => {
        date = date instanceof Date ? date : new Date();
        date.setSeconds(date.getSeconds() + seconds);

        return date;
    },
    formattedDate: (date?: Date, format?: string) => {
        date   = date instanceof Date ? date : new Date();
        format = format === undefined ? storage.account.preferences.get(`dateTimeFormat`) : format;
        format = format === undefined || format === null ? `Y-m-d H:i:s` : format;

        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hour = date.getHours();
        const minute = date.getMinutes();
        const second = date.getSeconds();
        const milliseconds = date.getMilliseconds();

        format = format.replace(`Y`, year.toString());
        format = format.replace(`y`, year.toString().substring(-2));
        format = format.replace(`M`, month.toString());
        format = format.replace(`m`, z(month));
        format = format.replace(`D`, day.toString());
        format = format.replace(`d`, z(day));
        format = format.replace(`H`, z(hour));
        format = format.replace(`h`, hour.toString());
        format = format.replace(`i`, z(minute));
        format = format.replace(`S`, second.toString());
        format = format.replace(`s`, z(second));
        format = format.replace(`U`, z(milliseconds));
        format = format.replace(`u`, milliseconds.toString());

        return format;
    },
    getFirstDate: (date?: Date) => {
        date = date === undefined ? new Date() : date;

        return new Date(date.getFullYear(), date.getMonth(), 1);
    },
    getLastDate: (date?: Date) => {
        date = date === undefined ? new Date() : date;

        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    },
    getDays: function (date?: Date): number[] {
        return [...new Array(this.getLastDate(date).getDate())].map((_, i) => i + 1);
    },
    getMonthName: (date?: Date): string|undefined => {
        const monthNames = [`January`, `February`, `March`, `April`, `May`, `June`, `July`, `August`, `September`, `October`, `November`, `December`];
        date   = date instanceof Date ? date : new Date();

        return monthNames[date.getMonth()] || undefined;
    },
    getDayOfWeekName: (date?: Date): string|undefined => {
        const dayOfWeek = [`Sunday`, `Monday`, `Tuesday`, `Wednesday`, `Thursday`, `Friday`, `Saturday`];
        date   = date instanceof Date ? date : new Date();

        return dayOfWeek[date.getDay()] || undefined;
    },
    getTodayDate: (): Date => {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        return today;
    },
};

export default DateTime;